import React from 'react';
import {
  Typography,
  List,
  Collapse,
  Button,
  Row,
  Col,
  message,
  Tooltip,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './ResultBox.css';
import { QuestionCircleOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';

interface ResultProps {
  value?: string;
  alternatives?: string[];
  shouldShow: boolean;
}

function copied() {
  message.success(
    'Il codice fiscale è stato copiato. Usa CTRL+V per incollarlo dove vuoi.',
  );
}

const ResultBox: React.FC<ResultProps> = (props) => {
  return (
    <QueueAnim type="alpha">
      {props.shouldShow ? (
        <div key={1}>
          <Row justify="center">
            <Col xs={18} lg={8} className="result-col">
              <div className="result-container">
                <Typography.Title level={4} className="result-code">
                  {props.value}
                </Typography.Title>
                <CopyToClipboard text={props.value} onCopy={copied}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<CopyOutlined />}
                    className="result-copy-button"
                  />
                </CopyToClipboard>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={18} lg={8} className="result-col">
              <div className="result-container">
                <Tooltip
                  title="L'omocodia si verifica se due persone hanno gli stessi dati anagrafici. 
          In questo caso l'ufficio anagrafe provvede a modificare il codice fiscale e non è possibile prevedere con certezza la variazione.
          Vengono qui suggerite alcune modifiche possibili."
                >
                  <QuestionCircleOutlined className="info-omocodie" />
                </Tooltip>
                <Collapse ghost className="result-code">
                  <Collapse.Panel header="Alternative per omocodia" key="1">
                    <List
                      dataSource={props.alternatives}
                      renderItem={(item) => (
                        <Row justify="center">
                          <Col>
                            <List.Item>{item}</List.Item>
                          </Col>
                        </Row>
                      )}
                    />
                  </Collapse.Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
    </QueueAnim>
  );
};

export default ResultBox;
