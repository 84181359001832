import React, { useState } from 'react';
import { Button, Input, Modal, message, Form, Typography } from 'antd';
import Paragraph from 'antd/lib/skeleton/Paragraph';

const InfoButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button ghost onClick={showModal}>
        Info
      </Button>
      <Modal
        title="Info"
        visible={isModalVisible}
        destroyOnClose={true}
        onCancel={handleOk}
        footer={[
          <Button key="back" onClick={handleOk}>
            Indietro
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          Le informazioni utilizzate riguardo Comuni e Province sono state
          tratte dal sito dell'Agenzia delle Entrate. Il codice generato
          corrisponde alle regole del D.M. del 12.3.1974.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Ricordiamo che l'uso per scopi illegali di un codice fiscale fittizio
          o non attribuito dal Ministero delle Finanze è severamente punito
          dalla legge.
        </Typography.Paragraph>
        <Typography.Paragraph>
          I dati inseriti sono utilizzati esclusivamente per il calcolo del
          codice fiscale. Non sono conservati dallo sviluppatore.
        </Typography.Paragraph>
      </Modal>
    </>
  );
};

export default InfoButton;
