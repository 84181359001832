import React from 'react';
import { PageProps } from 'gatsby';

import { Typography, Layout, Divider, Space, Grid, Row, Col } from 'antd';
const { Header, Footer, Content} = Layout;
const { Title } = Typography;
const { useBreakpoint } = Grid;
import {
  IdcardFilled,
  IdcardOutlined,
  ArrowRightOutlined,
  SafetyOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import './index.css';
import CodiceForm from '@/components/CodiceForm/CodiceForm';
import VerificaBox from '@/components/VerificaBox/VerificaBox';
import ContactButton from '@/components/ContactButton/ContactButton';
import InfoButton from '@/components/InfoButton/InfoButton';
import SEO from '@/components/SEO';

const Home: React.FC<PageProps> = () => {
  const screens = useBreakpoint();
  return (
    <main>
      <Layout>
        <SEO title="Home"/>
        <Header className="header">
          <Title className="title-label" level={screens.xs ? 3 : 1}><IdcardFilled /> Codice Fiscale</Title>
        </Header>
        <Content className="content-box">
          <Divider orientation="left">
            <SolutionOutlined /> <ArrowRightOutlined style={{ fontSize: 14 }} />{' '}
            <IdcardOutlined /> Calcolo Codice Fiscale
          </Divider>
          <div className="form-container">
            <CodiceForm />
          </div>
          <Divider orientation="left">
            <IdcardOutlined /> <ArrowRightOutlined style={{ fontSize: 14 }} />{' '}
            <SafetyOutlined /> Verifica Codice Fiscale
          </Divider>
          <VerificaBox />
        </Content>
        <Footer className="footer">
            <Row justify="center">
              <Col xs={{span: 10}} span={4}>
                <InfoButton />
              </Col>
              <Col xs={{span: 10}} span={4}>
                <ContactButton />
              </Col>
            </Row>
            <Divider />
            <Row justify="center">
              <Col span={20}>
                <Typography.Title className="footer-text" level={5}>
                  Made by Claudio Santonastaso
                </Typography.Title>
              </Col>
            </Row>
        </Footer>
      </Layout>
    </main>
  );
};

export default Home;
