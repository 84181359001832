import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';

interface SexFieldProps {
  onChange?: (value: RadioChangeEvent) => void;
  value: string;
}
const SexField: React.FC<SexFieldProps> = (props) => {
  return (
    <Radio.Group
      defaultValue={props.value}
      onChange={props.onChange}
      style={{ width: '100%' }}
    >
      <Radio value={'M'}>Maschio</Radio>
      <Radio value={'F'}>Femmina</Radio>
    </Radio.Group>
  );
};

export default SexField;
