import React from 'react';
import { Typography } from 'antd';

interface TitledProps {
  title?: string;
}

const Titled: React.FC<TitledProps> = (props) => {
  return (
    <div>
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {props.title}
      </Typography.Title>
      {props.children}
    </div>
  );
};

Titled.defaultProps = { title: '&nbsp;' };

export default Titled;
