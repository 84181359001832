import React, { useState } from 'react';
import { useForm } from '@formspree/react';
import { Button, Input, Modal, message, Form, Typography } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: 'Questa non sembra una email valida.',
  },
  string: {
    range: 'Il messaggio deve avere una lunghezza compresa fra ${min} e ${max} caratteri.',
  },
};
/* eslint-enable no-template-curly-in-string */

const ContactButton = () => {
  const [state, handleSubmit, resetForm] = useForm('mpzkgqob');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
      resetForm();
    setIsModalVisible(true);
  };

  const handleError = () => {
    message.error(
        "C'è stato un problema durante l'invio. Per favore riprova",
      );
    //setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Button ghost onClick={showModal}>
        Supporto
      </Button>
      <Modal
        title="Invia messaggio"
        visible={isModalVisible}
        destroyOnClose={true}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Indietro
          </Button>,
        ]}
      >
        <Form
          {...layout}
          name="basic"
          validateMessages={validateMessages}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          onFinishFailed={handleError}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Inserisci la tua email' },
              { type: 'email' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Messaggio"
            label="Messaggio"
            rules={[
              { required: true, message: 'Inserisci un messaggio' },
              { type: 'string', min: 10, max: 360 },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            loading={state.submitting}
            disabled={state.succeeded}
          >
            Invia
          </Button>
      </Form.Item>
      {state.succeeded ?
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Typography.Text><CheckCircleTwoTone
    twoToneColor="#52c41a"
  /> Il tuo messaggio è stato inviato. </Typography.Text>
  </Form.Item>
      :
      null}
        </Form>
      </Modal>
    </>
  );
};

export default ContactButton;
