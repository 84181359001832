import React, { useState } from 'react';

import { Row, Col, Button, DatePicker, Input, Space } from 'antd';
import Titled from '@/components/Titled/Titled';
import defGutter from '../DefaultGutter';
import './CodiceForm.css';
import CityField from '@/components/CityField/CityField';
import SexField from '@/components/SexField/SexField';
import CodiceFiscale from 'codice-fiscale-js';
import ResultBox from '../ResultBox/ResultBox';
import moment from 'moment';

const DefaultRow = ({ justification, children }) => (
  <Row style={{ marginBottom: 8 }} gutter={defGutter} justify={justification}>
    {children}
  </Row>
);

const TitledCol = ({ label, children }) => (
  <Col style={{ marginBottom: 8 }} xs={24} lg={12}>
    <Titled title={label}>{children}</Titled>
  </Col>
);

enum Field {
  name,
  surname,
  gender,
  date,
  place,
}

const CodiceForm: React.FC = () => {
  const [name, setName] = useState<string>('Mario');
  const [surname, setSurname] = useState<string>('Rossi');
  const [gender, setGender] = useState<string>('M');
  const [day, setDay] = useState<number>(7);
  const [month, setMonth] = useState<number>(7);
  const [year, setYear] = useState<number>(1997);
  const [birthplace, setBirthplace] = useState<string>('Zanzibar');
  const [birthplaceProvincia, setBirthplaceProvincia] = useState<string>('EE');
  const [omocodie, setOmocodie] = useState<string[]>([]);

  const [codiceFiscale, setCodiceFiscale] = useState<CodiceFiscale>(
    calcolaCodice(),
  );
  const [showResults, setShowResults] = useState<boolean>(false);

  function calcolaCodice() {
    return new CodiceFiscale({
      name: name,
      surname: surname,
      gender: gender,
      day: day,
      month: month,
      year: year,
      birthplace: birthplace,
      birthplaceProvincia: birthplaceProvincia,
    });
  }

  function calcAndShow() {
    setCodiceFiscale(calcolaCodice());
    setOmocodie(codiceFiscale.omocodie());
    setShowResults(true);
  }

  let handleChange = (field: Field, value) => {
    setShowResults(false);
    switch (field) {
      case Field.name:
        setName(value);
        break;
      case Field.surname:
        setSurname(value);
        break;
      case Field.date:
        setDay(value.date());
        setMonth(value.month() + 1);
        setYear(value.year());
        break;
      case Field.place:
        setBirthplaceProvincia(value[0].value);
        setBirthplace(value[1].label.split(' o ')[0]);
        break;
      case Field.gender:
        setGender(value);
        break;
      default:
        console.log('Unknown field type.');
        break;
    }
  };

  return (
    <Space className="form-box" direction="vertical">
      <DefaultRow justification="center">
        <TitledCol label="Nome">
          <Input
            value={name}
            onChange={(e) => {
              handleChange(Field.name, e.target.value);
            }}
          />
        </TitledCol>
        <TitledCol label="Cognome">
          <Input
            value={surname}
            onChange={(e) => {
              handleChange(Field.surname, e.target.value);
            }}
          />
        </TitledCol>
      </DefaultRow>
      <DefaultRow justification="center">
        <TitledCol label="Data di nascita">
          <DatePicker
            defaultValue={moment('07/07/1997', 'DD/MM/YYYY')}
            format={'DD/MM/YYYY'}
            onChange={(moment) => {
              handleChange(Field.date, moment);
            }}
          />
        </TitledCol>
        <TitledCol label="Luogo di nascita">
          <CityField
            onChange={(_value, options) => {
              handleChange(Field.place, options);
            }}
          />
        </TitledCol>
      </DefaultRow>
      <DefaultRow justification="start">
        <TitledCol label="Sesso">
          <SexField
            value="M"
            onChange={(e) => {
              handleChange(Field.gender, e.target.value);
            }}
          />
        </TitledCol>
        <TitledCol label="&nbsp;">
          <Row>
            <Button
              type="primary"
              style={{ margin: 'auto' }}
              onClick={calcAndShow}
            >
              Calcola
            </Button>
          </Row>
        </TitledCol>
      </DefaultRow>
      <ResultBox
        value={codiceFiscale.cf}
        alternatives={omocodie}
        shouldShow={showResults}
      />
    </Space>
  );
};

export default CodiceForm;
