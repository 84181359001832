import React, { useState } from 'react';
import { Input, Row, Col, Collapse, Descriptions, Typography } from 'antd';
import { CheckCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import CodiceFiscale from 'codice-fiscale-js';
import QueueAnim from 'rc-queue-anim';

const iconStyle = { fontSize: 32, margin: 4 };
const VerificaBox: React.FC = () => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [gender, setGender] = useState<string>('M');
  const [day, setDay] = useState<number>(7);
  const [month, setMonth] = useState<number>(7);
  const [year, setYear] = useState<number>(1997);
  const [birthplace, setBirthplace] = useState<string>('Zanzibar');
  const [birthplaceProvincia, setBirthplaceProvincia] = useState<string>('EE');

  const onVerify = (value) => {
    const cfValid = CodiceFiscale.check(value);
    if (cfValid) {
      const cf = CodiceFiscale.computeInverse(value);
      setGender(cf.gender);
      setBirthplace(cf.birthplace);
      setBirthplaceProvincia(cf.birthplaceProvincia);
      setDay(cf.day);
      setMonth(cf.month);
      setYear(cf.year);
    }
    setIsValid(cfValid);
    setChecked(true);
  };

  const onChange = (value) => {
    setChecked(false);
  };

  return (
    <div>
      <Row justify="center" gutter={12}>
        <Col xs={17} lg={11}>
          <Input.Search
            placeholder="Inserisci il codice"
            allowClear
            enterButton="Verifica"
            size="large"
            onSearch={onVerify}
            onChange={onChange}
          />
        </Col>
        <Col xs={1} lg={1}>
          <QueueAnim type="alpha">
            {checked ? (
              isValid ? (
                <CheckCircleTwoTone
                  key={1}
                  style={iconStyle}
                  twoToneColor="#52c41a"
                />
              ) : (
                <WarningTwoTone
                  key={1}
                  style={iconStyle}
                  twoToneColor="#fe8080"
                />
              )
            ) : null}
          </QueueAnim>
        </Col>
      </Row>
      <QueueAnim type="alpha">
        {isValid ? (
          <Row justify="center" key={1}>
            <Col xs={18} lg={14} className="result-col">
              <div className="result-container">
                <Collapse ghost className="result-code">
                  <Collapse.Panel header="Altre informazioni" key="1">
                    <Descriptions column={2}>
                      <Descriptions.Item label="Provincia">
                        {birthplaceProvincia}
                      </Descriptions.Item>
                      <Descriptions.Item label="Luogo di nascita">
                        {birthplace}
                      </Descriptions.Item>
                      <Descriptions.Item label="Sesso">
                        {gender}
                      </Descriptions.Item>
                      <Descriptions.Item label="Data di Nascita">
                        {day}/{month}/{year}
                      </Descriptions.Item>
                    </Descriptions>
                  </Collapse.Panel>
                </Collapse>
              </div>
            </Col>
          </Row>
        ) : null}
      </QueueAnim>
    </div>
  );
};

export default VerificaBox;
