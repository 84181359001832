import { Cascader } from 'antd';
import { OPTIONSComuni } from './optionsComuni';
import React from 'react';
import './CityField.css';
import { CascaderOptionType, CascaderValueType } from 'antd/lib/cascader';

function filter(inputValue, path) {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
  );
}

interface CityFieldProps {
  onChange?: (
    value: CascaderValueType,
    selectedOptions?: CascaderOptionType[],
  ) => void;
}

const CityField: React.FC<CityFieldProps> = (props) => {
  return (
    <Cascader
      options={OPTIONSComuni}
      onChange={props.onChange}
      placeholder="Please select"
      showSearch={{ filter }}
      defaultValue={['Estero', 'ZANZIBAR']}
    />
  );
};

export default CityField;
